@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&family=Playfair+Display:ital@1&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0;
  text-decoration: none;
  list-style: none;
  background-color: #404040;
  color: #cccccc;
  font-family: "Open Sans", sans-serif;
}

body {
  font-size: 10px;
  overflow-x: hidden;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  width: 100vw;
  padding: 1.2rem;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
}

.header > a {
  font-family: "Playfair Display", serif;
  font-size: 1.5rem;
  color: #fcba03;
  -ms-flex-negative: 2;
      flex-shrink: 2;
  -webkit-animation: fadeInLeft 2s ease-in-out;
          animation: fadeInLeft 2s ease-in-out;
}

.header .header--nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-animation: fadeInRight 2s ease-in-out;
          animation: fadeInRight 2s ease-in-out;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.header .header--nav a {
  font-size: 1rem;
  margin: 0 4px;
  border-bottom: solid 1.5px #404040;
  -webkit-transition: all 0.75s;
  transition: all 0.75s;
}

.header .header--nav a:hover {
  color: white;
  border-bottom: solid 1.5px white;
}

.landing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing .landing--initials {
  width: 80%;
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  color: #fcba03;
  margin-bottom: 30px;
  -webkit-animation: fadeInLeft 2s ease-in-out;
          animation: fadeInLeft 2s ease-in-out;
}

.landing .landing--menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-animation: fadeInRight 2s ease-in-out;
          animation: fadeInRight 2s ease-in-out;
}

.landing .landing--menu a {
  font-size: 1.1rem;
  margin-right: 8px;
  border-bottom: solid 1.5px #404040;
  -webkit-transition: all 0.75s;
  transition: all 0.75s;
}

.landing .landing--menu a:hover {
  color: white;
  border-bottom: solid 1.5px white;
}

.about {
  width: 100vw;
  height: 100vh;
}

.about .about--container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  height: 100%;
  margin: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.about .about--container h1 {
  font-size: 1.2rem;
  font-weight: 200;
  padding: 0.5rem 0;
  opacity: 0;
  -webkit-animation: fadeIn 2s 1.5s ease-in-out forwards;
          animation: fadeIn 2s 1.5s ease-in-out forwards;
}

.about .about--container h2 {
  color: #fcba03;
  font-size: 1.5rem;
  opacity: 0;
  -webkit-animation: fadeIn 2s 2s ease-in-out forwards;
          animation: fadeIn 2s 2s ease-in-out forwards;
  font-family: "Playfair Display", serif;
}

.about .about--container p {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
  height: 33%;
}

.about .about--container h2,
.about .about--container p {
  font-weight: 200;
  padding: 0.5rem 0;
  letter-spacing: 1px;
}

.about .about--container h2 a,
.about .about--container p a {
  color: #fcba03;
  font-family: "Playfair Display", serif;
}

.projects {
  width: 100vw;
  height: 100vh;
}

.projects .projects--carousel--container {
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.projects .projects--carousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 80%;
  margin-top: 20%;
}

.projects .projects--carousel > img {
  margin: 5px;
  border-radius: 2rem;
  border: 1px solid inherit;
  max-height: 40px;
  padding: 0;
  opacity: 0;
  -webkit-animation: fadeIn 2s 1.5s ease-in-out forwards;
          animation: fadeIn 2s 1.5s ease-in-out forwards;
  -webkit-transition: border 1s;
  transition: border 1s;
  cursor: pointer;
}

.projects .projects--carousel > img:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.projects .projects--carousel .projects--carousel--item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
  padding: 0.5rem;
  border-radius: 1rem;
  opacity: 1;
  -webkit-box-shadow: 0px 9px 10px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 9px 10px 4px rgba(0, 0, 0, 0.25);
  background-color: #333333;
}

.projects .projects--carousel .projects--carousel--item .left {
  background-color: inherit;
  text-align: center;
}

.projects .projects--carousel .projects--carousel--item .left h2 {
  background-color: inherit;
  color: #fcba03;
  font-weight: 200;
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.projects .projects--carousel .projects--carousel--item .left h3 {
  background-color: inherit;
  font-size: 0.8rem;
  padding-bottom: 10px;
}

.projects .projects--carousel .projects--carousel--item .left img {
  background-color: inherit;
  opacity: 0.6;
  border-radius: 1rem;
  width: 90%;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  cursor: pointer;
}

.projects .projects--carousel .projects--carousel--item .left img:hover {
  opacity: 1;
}

.projects .projects--carousel .projects--carousel--item .right {
  background-color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.projects .projects--carousel .projects--carousel--item .right p {
  background-color: inherit;
  text-align: center;
  padding: 10px;
  font-size: 0.8rem;
}

.projects .projects--carousel .projects--carousel--item .right .tech {
  background-color: inherit;
  color: #fcba03;
}

.projects .projects--carousel .projects--carousel--item .right a {
  padding-top: 10px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  background-color: inherit;
  cursor: pointer;
}

.projects .projects--carousel .projects--carousel--item .right a img {
  opacity: 0.7;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  width: 30px;
  background-color: inherit;
}

.projects .projects--carousel .projects--carousel--item .right a img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 1;
}

.skills {
  width: 100vw;
  height: 100vh;
}

.skills .skills--container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
  margin: 0 auto;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: unset;
  padding-top: 10%;
}

.skills .skills--container .skills--section {
  border-radius: 1rem;
  -webkit-box-shadow: 0px 9px 10px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 9px 10px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  width: 100%;
  margin: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #333333;
}

.skills .skills--container .skills--section h1 {
  font-family: "Playfair Display", serif;
  color: #fcba03;
  font-weight: 200;
  font-size: 1.4rem;
  text-align: center;
  background-color: inherit;
}

.skills .skills--container .skills--section .skills-section--item--container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  width: 95%;
  margin: 0 auto;
  gap: 0 10px;
  background-color: inherit;
}

.skills .skills--container .skills--section .skills-section--item--container .skills--item {
  max-height: 100px;
  text-align: center;
  margin: 10px 5px;
  background-color: inherit;
}

.skills .skills--container .skills--section .skills-section--item--container .skills--item p {
  background-color: inherit;
  font-size: 0.9rem;
  margin: 5px 0;
}

.skills .skills--container .skills--section .skills-section--item--container .skills--item img {
  background-color: inherit;
  opacity: 0.5;
  max-height: 50%;
  max-width: 75%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.skills .skills--container .skills--section .skills-section--item--container .skills--item img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 1;
}

.contact {
  width: 100vw;
  height: 100vh;
}

.contact .contact--container {
  height: 100%;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
}

.contact .contact--container h2 {
  width: 90%;
  font-family: "Playfair Display", serif;
  color: #fcba03;
  font-weight: 200;
  font-size: 1.3rem;
  text-align: center;
  padding: 10px 0;
  opacity: 0;
  -webkit-animation: fadeIn 2s 0.5s ease-in-out forwards;
          animation: fadeIn 2s 0.5s ease-in-out forwards;
}

.contact .contact--container .contact--links--container {
  opacity: 0;
  -webkit-animation: fadeIn 2s 1s ease-in-out forwards;
          animation: fadeIn 2s 1s ease-in-out forwards;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 20px 0;
}

.contact .contact--container .contact--links--container a {
  padding: 20px;
}

.contact .contact--container .contact--links--container a img {
  opacity: 0.8;
  max-height: 40px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.contact .contact--container .contact--links--container a img:hover {
  opacity: 1;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media (min-width: 540px) {
  .header {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .header .header--nav a {
    margin-right: 12px;
  }
  .landing {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    text-align: center;
  }
  .landing .landing--initials {
    margin: 0;
  }
  .landing .landing--menu a {
    margin-right: 12px;
  }
  .about .about--container p {
    width: 60%;
  }
  .projects .projects--carousel--container .projects--carousel {
    margin: 0;
    height: 100%;
  }
  .projects .projects--carousel--container .projects--carousel .projects--carousel--item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .projects .projects--carousel--container .projects--carousel .projects--carousel--item .left {
    max-width: 50%;
    padding: 0.5rem;
  }
  .projects .projects--carousel--container .projects--carousel .projects--carousel--item .left h2 {
    font-size: 1.4rem;
  }
  .projects .projects--carousel--container .projects--carousel .projects--carousel--item .left h3 {
    font-size: 1rem;
  }
  .projects .projects--carousel--container .projects--carousel .projects--carousel--item .right {
    padding: 0.5rem;
  }
  .projects .projects--carousel--container .projects--carousel .projects--carousel--item .right p {
    font-size: 0.9rem;
  }
  .skills .skills--container {
    padding-top: 10%;
  }
  .skills .skills--container .skills--section {
    width: 60%;
  }
}

@media (min-width: 1079px) {
  .projects .projects--carousel--container .projects--carousel {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
  .projects .projects--carousel--container .projects--carousel .projects--carousel--item {
    max-width: 60%;
  }
  .skills .skills--container {
    padding-top: 5%;
    width: 90%;
  }
  .skills .skills--container .skills--section {
    width: 40%;
    margin: 0 auto 20px;
  }
  .skills .skills--container .skills--section .skills-section--item--container {
    -ms-grid-columns: (1fr)[5];
        grid-template-columns: repeat(5, 1fr);
  }
  .contact .contact--container .contact--links--container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .contact .contact--container .contact--links--container a {
    margin: 20px;
  }
}
