@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&family=Playfair+Display:ital@1&display=swap");

// ---------------RESETS-----------------
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  text-decoration: none;
  list-style: none;
  background-color: hsl(0, 0%, 25%);
  color: hsl(0, 0%, 80%);
  font-family: "Open Sans", sans-serif;
}

body {
  font-size: 10px;
  overflow-x: hidden;
}
// ----------------ANIMATIONS------------------

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// -------------MIXINS------------------

@mixin page {
  width: 100vw;
  height: 100vh;
}

//------------MOBILE-----------------------
.header {
  width: 100vw;
  padding: 1.2rem;
  position: fixed;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  > a {
    font-family: "Playfair Display", serif;
    font-size: 1.5rem;
    color: hsl(44, 98%, 50%);
    flex-shrink: 2;
    animation: fadeInLeft 2s ease-in-out;
  }
  .header--nav {
    display: flex;
    justify-content: space-between;
    animation: fadeInRight 2s ease-in-out;
    flex-wrap: wrap;
    a {
      font-size: 1rem;
      margin: 0 4px;
      border-bottom: solid 1.5px hsl(0, 0%, 25%);
      transition: all 0.75s;
    }
    a:hover {
      color: hsl(0, 0%, 100%);
      border-bottom: solid 1.5px hsl(0, 0%, 100%);
    }
  }
}

.landing {
  display: flex;
  flex-direction: column;
  @include page;
  justify-content: space-evenly;
  align-items: center;
  .landing--initials {
    width: 80%;
    font-family: "Playfair Display", serif;
    font-size: 2rem;
    color: hsl(44, 98%, 50%);
    margin-bottom: 30px;
    animation: fadeInLeft 2s ease-in-out;
  }
  .landing--menu {
    display: flex;
    width: 80%;
    justify-content: flex-start;
    animation: fadeInRight 2s ease-in-out;
    a {
      font-size: 1.1rem;
      margin-right: 8px;
      border-bottom: solid 1.5px hsl(0, 0%, 25%);
      transition: all 0.75s;
    }
    a:hover {
      color: hsl(0, 0%, 100%);
      border-bottom: solid 1.5px hsl(0, 0%, 100%);
    }
  }
}

.about {
  @include page;
  .about--container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
      font-size: 1.2rem;
      font-weight: 200;
      padding: 0.5rem 0;
      opacity: 0;
      animation: fadeIn 2s 1.5s ease-in-out forwards;
    }
    h2 {
      color: hsl(44, 98%, 50%);
      font-size: 1.5rem;
      opacity: 0;
      animation: fadeIn 2s 2s ease-in-out forwards;
      font-family: "Playfair Display", serif;
    }
    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      height: 33%;
    }
    h2,
    p {
      font-weight: 200;
      padding: 0.5rem 0;
      letter-spacing: 1px;
      a {
        color: hsl(44, 98%, 50%);
        font-family: "Playfair Display", serif;
      }
    }
  }
}

.projects {
  @include page;
  .projects--carousel--container {
    max-width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .projects--carousel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 80%;
    margin-top: 20%;
    > img {
      margin: 5px;
      border-radius: 2rem;
      border: 1px solid inherit;
      max-height: 40px;
      padding: 0;
      opacity: 0;
      animation: fadeIn 2s 1.5s ease-in-out forwards;
      transition: border 1s;
      cursor: pointer;
    }
    > img:hover {
      border: 1px solid hsla(0, 0%, 0%, 0.3);
    }
    .projects--carousel--item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90%;
      padding: 0.5rem;
      border-radius: 1rem;
      opacity: 1;
      box-shadow: 0px 9px 10px 4px hsla(0, 0%, 0%, 0.25);
      background-color: hsl(0, 0%, 20%);
      .left {
        background-color: inherit;
        text-align: center;
        h2 {
          background-color: inherit;
          color: hsl(44, 98%, 50%);
          font-weight: 200;
          font-size: 1.2rem;
          padding-bottom: 10px;
        }
        h3 {
          background-color: inherit;
          font-size: 0.8rem;
          padding-bottom: 10px;
        }
        img {
          background-color: inherit;
          opacity: 0.6;
          border-radius: 1rem;
          width: 90%;
          transition: opacity 1s;
          cursor: pointer;
        }
        img:hover {
          opacity: 1;
        }
      }
      .right {
        background-color: inherit;
        display: flex;
        flex-direction: column;
        p {
          background-color: inherit;
          text-align: center;
          padding: 10px;
          font-size: 0.8rem;
        }
        .tech {
          background-color: inherit;
          color: hsl(44, 98%, 50%);
        }
        a {
          padding-top: 10px;
          align-self: center;
          background-color: inherit;
          cursor: pointer;
          img {
            opacity: 0.7;
            transition: all 0.7s;
            width: 30px;
            background-color: inherit;
          }
          img:hover {
            transform: scale(1.1);
            opacity: 1;
          }
        }
      }
    }
  }
}

.skills {
  @include page;
  .skills--container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
    height: unset;
    padding-top: 10%;
    .skills--section {
      border-radius: 1rem;
      box-shadow: 0px 9px 10px 4px hsla(0, 0%, 0%, 0.25);
      padding: 20px;
      width: 100%;
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      background-color: hsl(0, 0%, 20%);
      h1 {
        font-family: "Playfair Display", serif;
        color: hsl(44, 98%, 50%);
        font-weight: 200;
        font-size: 1.4rem;
        text-align: center;
        background-color: inherit;
      }
      .skills-section--item--container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 95%;
        margin: 0 auto;
        gap: 0 10px;
        background-color: inherit;
        .skills--item {
          max-height: 100px;
          text-align: center;
          margin: 10px 5px;
          background-color: inherit;
          p {
            background-color: inherit;
            font-size: 0.9rem;
            margin: 5px 0;
          }
          img {
            background-color: inherit;
            opacity: 0.5;
            max-height: 50%;
            max-width: 75%;
            transition: all 0.5s;
          }
          img:hover {
            transform: scale(1.2);
            opacity: 1;
          }
        }
      }
    }
  }
}

.contact {
  @include page;
  .contact--container {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    h2 {
      width: 90%;
      font-family: "Playfair Display", serif;
      color: hsl(44, 98%, 50%);
      font-weight: 200;
      font-size: 1.3rem;
      text-align: center;
      padding: 10px 0;
      opacity: 0;
      animation: fadeIn 2s 0.5s ease-in-out forwards;
    }
    .contact--links--container {
      opacity: 0;
      animation: fadeIn 2s 1s ease-in-out forwards;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      padding: 20px 0;
      a {
        padding: 20px;
        img {
          opacity: 0.8;
          max-height: 40px;
          transition: all 0.5s;
        }
        img:hover {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }
}

// ---------------IPAD-------------
@media (min-width: 540px) {
  .header {
    justify-content: space-around;
    .header--nav {
      a {
        margin-right: 12px;
      }
    }
  }

  .landing {
    flex-direction: row;
    text-align: center;
    .landing--initials {
      margin: 0;
    }
    .landing--menu {
      a {
        margin-right: 12px;
      }
    }
  }

  .about {
    .about--container {
      p {
        width: 60%;
      }
    }
  }

  .projects {
    .projects--carousel--container {
      .projects--carousel {
        margin: 0;
        height: 100%;
        .projects--carousel--item {
          flex-direction: row;
          .left {
            max-width: 50%;
            padding: 0.5rem;
            h2 {
              font-size: 1.4rem;
            }
            h3 {
              font-size: 1rem;
            }
          }
          .right {
            padding: 0.5rem;
            p {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }

  .skills {
    .skills--container {
      padding-top: 10%;
      .skills--section {
        width: 60%;
      }
    }
  }
}

// ------------DESKTOP---------------

@media (min-width: 1079px) {
  .projects {
    .projects--carousel--container {
      .projects--carousel {
        justify-content: space-evenly;
        .projects--carousel--item {
          max-width: 60%;
        }
      }
    }
  }

  .skills {
    .skills--container {
      padding-top: 5%;
      width: 90%;
      .skills--section {
        width: 40%;
        margin: 0 auto 20px;
        .skills-section--item--container {
          grid-template-columns: repeat(5, 1fr);
        }
      }
    }
  }

  .contact {
    .contact--container {
      .contact--links--container {
        justify-content: center;
        a {
          margin: 20px;
        }
      }
    }
  }
}
